import * as React from "react";
// @ts-ignore
import * as style from './ProductOverview.module.scss';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Link} from "gatsby";
import {useEffect, useState} from "react";
import MarketplaceFilter from "../../pages/marktplatz/MarketplaceFilter/MarketplaceFilter";
import Loading from "../Loading/Loading";
import TopSellerReworked from "../TopSellerReworked/TopSellerReworked";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Container, Row} from "react-bootstrap";
import {
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    faChevronRight
} from "@fortawesome/pro-light-svg-icons";
import Layout from "../Navigation/layout";
import {Client, handleApiError} from "../../services/ApiService";

type HeadlineProps = {
    products: Array<any>,
    ID: string,
    ShowFilter: boolean
}

function ProductOverview(props: HeadlineProps) {
    const [products, setProducts] = useState([]);
    const [newProducts, setNewProducts] = useState([]);
    const [initialProducts, setInitialProducts] = useState([]);
    const [initialMaxProducts, setInitialMaxProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [maxProducts, setMaxProducts] = useState([]);
    const [maxEndProducts, setMaxEndProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [wrapperHeight, setWrapperHeight] = useState(0);
    const [page, setPage] = useState(0);
    const [isMobile, setIsMobile] = useState(false)
    const [isSticky, setIsSticky] = useState(false)
    const [filteredProducts, setFilteredProducts] = useState([]);

    const filterID = "filterID" + props.ID;

    const pageSize = 42;

    const hasCidParameter = () => {
        let queryString = window.location.search;
        return queryString.includes("?cid[]");
    };

    useEffect(() => {
        console.log("ShowFilter in ProductOverview")
        console.log(props.ShowFilter)

        console.log("Props Products:")
        console.log(props.products)

        if (props.products.length > 0) {
            let idToIndexMap = {};
            props.products.forEach((item, index) => {
                idToIndexMap[item.ID] = index;
            });

            const ids: number[] = props.products.map((item) => item.ID);

            Client.Raw.get('Product', {
                params: {
                    'filter[Hidden]': 0,
                    'filter[ID]': ids,
                }
            }).then((response) => {
                const filteredProducts = response.data
                    .map((item) => ({
                        ...item,
                        originalIndex: idToIndexMap[item.ID],
                    }))
                    .sort((a, b) => a.originalIndex - b.originalIndex);

                let offset = (page * pageSize);
                console.log("Offset: " + offset);

                const slicedProducts = filteredProducts.slice(offset, offset + pageSize);
                setInitialProducts(slicedProducts.map((item) => ({ ...item, ID: item.ID })));
                setInitialMaxProducts(filteredProducts);
                setNewProducts(slicedProducts);
                setMaxEndProducts(filteredProducts);
                console.log("NEW MAX END PRODUCTS: ");
                console.log(maxEndProducts);

                console.log("Products from API:");
                console.log(filteredProducts);

            });
        }
    }, [])

    useEffect(() => {
        let offset = (page * pageSize);
        setNewProducts(initialMaxProducts.slice(offset, offset + pageSize));
        console.log("initialmax")
        console.log(initialMaxProducts.slice(offset, offset + pageSize))
        console.log("Change Page")
        console.log(newProducts)
    }, [page])

    useEffect(() => {
        console.log("New Products:")
        console.log(newProducts)
    }, [newProducts])

    useEffect(() => {
        console.log("Initial:")
        console.log(initialProducts)
    }, [initialProducts])

    useEffect(() => {
        setIsMobile(window.innerWidth < 991)
    }, [window.innerWidth]);

    function setProductsFromFilter(products: any) {
        if (!props.ShowFilter && initialProducts.length > 0 && !loading && hasCidParameter()) {
            setNewProducts(initialProducts.filter(item => window.location.search.includes(item.CategoryID)))
            setMaxEndProducts(maxProducts)
        }
    }

    function scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows() {
        document.getElementById(filterID).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    }


    return (
        <div id={'overviewContainer'}>
            <div className={style.marketPlaceWrapper + ' ProductOverview'} id={filterID}>
                {!isMobile ? <div className={style.normalFilter}>
                    <MarketplaceFilter setPage={setPage} page={page} filteringDone={setProductsFromFilter}
                                       filteringDoneMax={setMaxProducts}
                                       filterHeight={wrapperHeight} setLoading={setLoading}
                                       propsProducts={maxEndProducts} cats={true} filterID={filterID}
                                       showFilter={props.ShowFilter}/>
                </div> : null}
                <Container className={style.mobileContainer + " py-5 ps-5 ps-sm-0"}>
                    {isMobile ? <div className={style.mobileFilter + (isSticky ? ' ' + style.isSticky : '')}>
                        <MarketplaceFilter setPage={setPage} page={page} filteringDone={setProductsFromFilter}
                                           filteringDoneMax={setMaxProducts}
                                           filterHeight={wrapperHeight} setLoading={setLoading}
                                           propsProducts={maxEndProducts} cats={true} filterID={filterID}
                                           showFilter={props.ShowFilter}/>
                    </div> : null}
                    {newProducts.length === 0 && initialProducts.length === 0 ?
                        <Loading type={"border"} message={"Produkte werden geladen"}/> :
                        <>
                            {}
                            {console.log('shesh', newProducts)}
                            {console.log('shesh2', initialProducts)}
                            <TopSellerReworked products={newProducts.length !== 0 ? newProducts : initialProducts}/>
                            {newProducts.length > 0 || initialProducts.length > 0 ?
                                <div>
                                    <div
                                        className={'d-flex gap-3 align-items-center justify-content-center text-center ' + style.flexButtonContainer}>
                                        <div className={style.buttonItem}>
                                            {page > 0 ? <Button className={style.customButton}
                                                                onClick={() => {
                                                                    setPage(0);
                                                                    scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                                }}><span className={"d-lg-block d-none"}>Zur ersten Seite</span><span
                                                    className={"d-lg-none d-block"}><FontAwesomeIcon
                                                    icon={faChevronDoubleLeft}/></span></Button> :
                                                <Button className={style.customButton + ' ' + style.disabledButton}
                                                        disabled
                                                        onClick={() => {
                                                            setPage(0);
                                                            scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                        }}><span className={"d-lg-block d-none"}>Zur ersten Seite</span><span
                                                    className={"d-lg-none d-block"}><FontAwesomeIcon
                                                    icon={faChevronDoubleLeft}/></span></Button>}
                                        </div>

                                        <div className={style.buttonItem}>
                                            {page > 0 ? <Button className={style.customButton}
                                                                onClick={() => {
                                                                    setPage(page => --page);
                                                                    scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                                }}><span
                                                    className={"d-lg-block d-none"}>Zurück</span><span
                                                    className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronLeft}/></span></Button> :
                                                <Button className={style.customButton + ' ' + style.disabledButton}
                                                        disabled
                                                        onClick={() => {
                                                            setPage(page => --page);
                                                            scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                        }}><span className={"d-lg-block d-none"}>Zurück</span><span
                                                    className={"d-lg-none d-block"}><FontAwesomeIcon
                                                    icon={faChevronLeft}/></span></Button>}
                                        </div>

                                        <div className={style.buttonItem}>
                                            <span>{page + 1} / {Math.ceil(maxEndProducts.length / pageSize)}</span>
                                            {console.log(maxEndProducts.length)}
                                            {console.log(pageSize)}
                                        </div>


                                        <div className={style.buttonItem}>
                                            <div>
                                                {maxEndProducts.length <= pageSize || newProducts.length < pageSize && newProducts.length > 0 ?
                                                    <Button
                                                        className={style.customButton + ' ' + style.disabledButton}
                                                        disabled
                                                        onClick={() => {
                                                            setPage(page => ++page);
                                                            scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                        }}>
                                                        <span className={"d-lg-block d-none"}>Weiter</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronRight}/></span>
                                                    </Button> :
                                                    <Button className={style.customButton}
                                                            onClick={() => {
                                                                setPage(page => ++page);
                                                                scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                            }}>
                                                        <span className={"d-lg-block d-none"}>Weiter</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronRight}/></span>
                                                    </Button>
                                                }
                                            </div>
                                        </div>

                                        <div className={style.buttonItem}>
                                            {maxEndProducts.length <= pageSize || newProducts.length < pageSize && newProducts.length > 0 ?
                                                <Button className={style.customButton + ' ' + style.disabledButton}
                                                        disabled
                                                        onClick={() => {
                                                            setPage(0);
                                                            scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                        }}><span
                                                    className={"d-lg-block d-none"}>Zur letzten Seite</span><span
                                                    className={"d-lg-none d-block"}><FontAwesomeIcon
                                                    icon={faChevronDoubleRight}/></span></Button> :
                                                <Button className={style.customButton}
                                                        onClick={() => {
                                                            setPage(Math.ceil(maxEndProducts.length / pageSize) - 1);
                                                            scrollToBeginOfElementScrollFunctionThatMaybeWorksMaybeNotWhoKnows();
                                                        }}><span
                                                    className={"d-lg-block d-none"}>Zur letzten Seite</span><span
                                                    className={"d-lg-none d-block"}><FontAwesomeIcon
                                                    icon={faChevronDoubleRight}/></span></Button>}
                                        </div>
                                    </div>
                                </div> : null}
                        </>
                    }
                </Container>

            </div>
        </div>
    );
}

export default ProductOverview;
